import axios from "../../../axios/index.js";

const apiIntroducerUrl = "/introducers/";
const apiAdminUrl = "/admin/";


export default {
  login(payload) {
    return axios.post(apiIntroducerUrl + "auth/login", payload);
  },
  partnerLogin(email, pwd) {
    return axios.post("/auth/login", { email: email, password: pwd });
  },
  refreshToken() {
    return axios.post("auth/refreshToken", {
      refreshToken: JSON.stringify(localStorage.getItem("refreshToKen"))
    });
  },
  checkTokenExpiry(token) {
    return axios.post("checkTokenExpiry", { token });
  },
  resetPassword(password, token) {
    return axios.post("auth/resetPassword", {
      password: password,
      token: token
    });
  },
  resetAdminPassword(password, token) {
    return axios.post(apiIntroducerUrl + "auth/resetPassword", {
      password: password,
      token: token
    });
  },
  forgotPasswordAdmin(email) {
    return axios.post(apiIntroducerUrl + "auth/forgotPassword", { email: email });
  },
  /**
   * resend verification link
   * @param email
   * @returns {*}
   */
  resendVerificationMailAdmin(email) {
    return axios.post("auth/resendVerificationAdmin", { email: email });
  },
  changePassword(currentPassword, newPassword) {
    return axios.post(apiIntroducerUrl + "auth/changePassword", {
      oldPassword: currentPassword,
      newPassword: newPassword
    });
  },

  checkResetTokenExpiry(token) {
    return axios.get(`auth/isResetTokenExpired?token=${token}`);
  },
  verifyMail(token) {
    return axios.get(`auth/verify/${token}`);
  },

  logout() {
    return axios.post(apiIntroducerUrl + "logout");
  },

  fetchUserByRememberMeToken(payload) {
    return axios.post(apiAdminUrl + "auth/fetchUserDetail", payload);
  },
  LoginAs(payload) {
    return axios.post(
      "/auth/login-as-introducer",
      { introducerId: payload.introducerId },
      payload.config
    );
  },

};
