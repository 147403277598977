import axios from "@/axios";


export default {
  fetchListSettingByPage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`listSetting/${data.partnerId}/?name=${data.name}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  saveListSetting({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("listSetting/", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updatelistSetting({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`listSetting/${data.partnerId}/?name=${data.name}`, data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },


};
