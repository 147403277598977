/*=========================================================================================
  File Name: moduleHistoryActions.js
  Description: Partner Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import "firebase/auth";
import axios from "@/axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  fetchMerchantList({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/introducers/sentRequests/" + id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  fetchMerchantDetail({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/introducers/fetchMerchantDetail/" + id) // id is merchant id 
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
 
};
