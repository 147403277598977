/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
  fetchUsers({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`introducers/${data.introducerId}/users${data.options}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addNewUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`introducers/users`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  sendCredentialsToUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/introducers/users/sendCredentials/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchUserById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`introducers/users/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  fetchPlaformProfile({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`introducers/platform-profile/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateAdminProfile({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios
        .post(`introducers/profile-update`, user)
        .then((response) => {
          commit("UPDATE_USER_INFO", response.data.data, {
            root: true,
          });
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updateUserDetails({ commit }, data) {
    console;
    return new Promise((resolve, reject) => {
      axios
        .patch(`introducers/users/${data.id}`, data.data)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  removeUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`introducers/users/${id}`)
        .then((response) => {
          // commit('REMOVE_RECORD', id)
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchUserForCsv() {
    return new Promise((resolve, reject) => {
      axios
        .get(`introducers/user-management/getUserForCsv`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateAdminImage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`introducers/upload-profile-image`, data.obj, data.config, {
          timeout: 500000,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((ex) => {
          return reject(ex);
        });
    });
  },
  updatePlaformProfile({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`introducers/platform-profile/${data.id}`, data.data).then((response) => {
        resolve(response);
      }).catch((e) => {
        reject(e);
      });
    });
  },
};
