import store from "../store/store";

export const auth = (to, from, next) => {
  const token = localStorage.getItem("accessToken");
  const publicPages = [
    "/login",
    "/forgot-password",
    "/verify-email/" + to.params.token,
    "/reset-password/" + to.params.token,
    "/pages/error-419",
    "/success"
  ];
  const authRequired = !publicPages.includes(to.path);

  if (to.path === "/login") {
    if (token) {
      return next("dashboard");
    }
  }

  if (!authRequired && token) {
    return next("/login");
  }

  if (authRequired && !token && to.name !== "user-reset-password") {
    store.state.redirectAfterLogin.name = to.name;
    store.state.redirectAfterLogin.params = to.params;

    if (from.path !== "/admin/login") {
      return next("/login");
    }
  }

  return next();
};
