<!-- =========================================================================================
    File Name: VxBreadcrumb.vue
    Description: Breadcrumb component
    Component Name: VxBreadcrumb
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template functional>
    <div class="vx-breadcrumb" :class="data.staticClass">
      <ul class="flex flex-wrap items-center">
        <li class="inline-flex items-center">
          <router-link to="/dashboard">Home </router-link>
          <span class="breadcrumb-separator mx-2 flex items-start">
            <vs-icon icon="chevron_right" size="small" color="#757575"></vs-icon>
            <!-- <feather-icon :icon="props.isRTL ? 'ChevronsLeftIcon' : 'chevron_right'" svgClasses="w-4 h-4" /> -->
          </span>
        </li>
        <li
          v-for="(link, index) in props.route.meta.breadcrumb.slice(1, -1)"
          :key="index"
          class="inline-flex items-center"
        >
          <router-link :to="link.url" v-if="link.url">{{
            link.title
          }}</router-link>
          <span class="text-primary cursor-default" v-else>{{ link.title }}</span>
  
          <span class="breadcrumb-separator mx-2 flex items-start">
            <vs-icon icon="chevron_right" size="small" color="#757575"></vs-icon>
            <!-- <feather-icon :icon="props.isRTL ? 'ChevronsLeftIcon' : 'ChevronsRightIcon'" svgClasses="w-4 h-4" /> -->
          </span>
        </li>
        <li class="inline-flex">
          <span
            v-if="props.route.meta.breadcrumb.slice(-1)[0].active"
            class="cursor-default b-inactive"
            >{{ props.route.meta.breadcrumb.slice(-1)[0].title }}
          </span>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    name: "vx-breadcrumb",
  };
  </script>
  