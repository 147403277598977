import auth from "@/auth/authService";
import firebase from 'firebase/app';
import 'firebase/auth';

export default {
  questionAnswers:[],
  isUserLoggedIn: () => {
    let isAuthenticated = false;

    // get firebase current user
    const firebaseCurrentUser = firebase.auth().currentUser;
    isAuthenticated = (auth.isAuthenticated() || firebaseCurrentUser);
    return (localStorage.getItem('userInfo') && isAuthenticated);
  },
}
