import state from "./moduleListSettingState";
import mutations from "./moduleListSettingMutation";
import actions from "./moduleListSettingActions";
import getters from "./moduleListSettingGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
