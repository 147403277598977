import axios from "../axios/index.js"
import store from "../../store/store.js"
import router from "@/router"
import { clearLocalStorage } from "../../helper/localStorage.js";
import { name } from '../../../package.json';

// Token Refresh
let isAlreadyFetchingAccessToken = false;
let subscribers = [];
const appLoading = document.getElementById("loading-bg");

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token));
}

export default {
  init() {
    axios.interceptors.request.use((config) => {
      appLoading.style.display = "inline-block";
      const token = localStorage.getItem('accessToken');

      if (token) {
        config.headers.Authorization = 'Bearer ' + token;
      }

      // attach a header to indentify the portal request on server
      config.headers.requestFrom = name;

      return config;
    }, (error) => {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
      if (appLoading) {
        appLoading.style.display = "none";
      }

      if (response.config.method == 'get') {
        const appVersion = response.headers['appversion'];

        if (appVersion && appVersion !== localStorage.getItem('appVersion')) {
          localStorage.setItem('appVersion', appVersion);
          window.location.reload(true);
        }
      }

      return response;
    }, function (error) {
      if (appLoading) {
        appLoading.style.display = "none";
      }

      const { response } = error;

      if (response && response.status === 401) {
        if (!isAlreadyFetchingAccessToken) {
          store.dispatch("auth/fetchAccessToken")
            .then((access_token) => {
              isAlreadyFetchingAccessToken = false;
              onAccessTokenFetched(access_token);
            }).catch(err=> {
              clearLocalStorage();
              router.push(router.currentRoute.query.to || "/").catch(() => {});
            });
        }
      }

      return Promise.reject(error);
    });
  }
}
