import axios from "@/axios";
export default {
  saveLogo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/introducers/uploadLogo", payload.data, payload.config).then(res => {
        return resolve(res);
      }).catch(err => {
        return reject(err.response);
      });
    });
  },
  removeLogo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/introducers/removeLogo", payload).then((res) => {
        return resolve(res);
      }).catch((err) => {
        return reject(err.response);
      })
    })
  },
 
  fetchLogo({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("/introducers/fetchLogo/" + id).then(res => {
        return resolve(res);
      }).catch(err => {
        return reject(err.response);
      });
    });
  },
 
};